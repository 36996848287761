<template>
  <div class="nav-back">
    <div class="nav-back-btn" v-if="navBackMsg.backBtn" @click="navBack">
      <van-icon name="arrow-left" />
    </div>
    <div class="nav-back-title">{{ navBackMsg.title }}</div>
  </div>
</template>

<script type="text/ecmascript-6">
/**
  navBackMsg: {
    title: "消息通知",       // 导航文字内容
    backBtn: true           //  返回按钮是否显示
  }
 */
export default {
  props: {
    navBackMsg: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    navBack() {
      // 返回
      // 返回上一步
      this.$router.back(-1);
    },
  },
  components: {},
  created() {
    // console.log(this.navBackMsg);
  },
};
</script>

<style scoped lang="scss">
.nav-back {
  position: relative;
  width: 100%;
  height: 44px;
  overflow: hidden;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .nav-back-btn {
    position: absolute;
    top: 12px;
    left: 0;

    color: #141614;
    font-size: 18px;

    box-sizing: border-box;
    padding-left: 0.2rem;
  }
  .nav-back-title {
    width: 100%;
    font-size: 0.45rem;
    color: #333;
  }
}
</style>
