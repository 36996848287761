<template>
  <div class="select-dom">
    <van-field
      readonly
      clickable
      :required="required"
      :value="value1"
      :name="label"
      :label="label"
      :disabled="disabled"
      placeholder="请选择"
      right-icon="arrow"
      input-align="right"
      @click="filedClick"
    />
    <van-popup
      v-model="showPopup"
      position="bottom"
      get-container=".address-contrain"
    >
      <van-picker
        show-toolbar
        :default-index="index"
        :columns="list"
        @confirm="onConfirm"
        @cancel="showPopup = false"
      />
    </van-popup>
  </div>
</template>
<script>
/**
 * list:下拉选择项
 * value：值
 * label：标题
 * required:是否必填
 * disabled：是否禁用
 */
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      default: "",
    },
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      index: 0,
      showPopup: false,
      value1: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        this.setIndex(val);
      },
    },
  },
  created() {
    this.setIndex(this.value);
  },
  methods: {
    onConfirm(obj) {
      this.showPopup = false;
      this.$emit("update:value", obj.value);
      this.setIndex(obj.text);
    },
    setIndex(value) {
      if (value) {
        this.list.forEach((element, index) => {
          if (element.value == value) {
            this.index = index;
            this.value1 = element.text;
          }
        });
      }
    },
    filedClick() {
      if (this.disabled) return;
      this.showPopup = true;
    },
  },
};
</script>