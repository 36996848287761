<template>
  <div class="picker-dom">
    <van-field
      readonly
      clickable
      :required="required"
      :disabled="disabled"
      :value="value"
      :name="label"
      :label="label"
      placeholder="请选择"
      right-icon="arrow"
      input-align="right"
      @click="filedClick"
    />
    <van-popup
      v-model="showPopup"
      position="bottom"
      get-container=".address-contrain"
    >
      <van-datetime-picker
        v-model="currentDate"
        :type="type"
        :title="title"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onConfirm"
        @cancel="showPopup = false"
      />
    </van-popup>
  </div>
</template>
<script>
/**
 * value：值
 * label：标题
 * required:是否必填
 * disabled:是否禁用
 * title：弹框标题
 * type：日期类型
 * minDate：日期选择范围，最小值
 * maxDate：日期选择范围，最大值
 */
export default {
  props: {
    value: {
      default: "",
    },
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      default: "选择日期",
    },
    type: {
      default: "date",
    },
    minDate: {
      default: () => {
        return new Date(1900, 0, 1);
      },
    },
    // maxDate: {
    //   defrult: () => {
    //     return new Date(2100, 10, 1);
    //   },
    // },
  },
  data() {
    return {
      index: 0,
      showPopup: false,
      maxDate: new Date(2100, 11, 31),
      currentDate: new Date(2021, 0, 1),
    };
  },

  created() {
    if (this.value) {
      this.currentDate = new Date(this.value);
    }
  },
  methods: {
    onConfirm(value) {
      this.showPopup = false;
      let date = this.timestampToDate(value);
      this.currentDate = new Date(date);
      this.$emit("update:value", date);
    },
    // 时间戳转日期
    addZero(n) {
      return n < 10 ? "0" + n : n;
    },
    timestampToDate(data) {
      const time = new Date(data);
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      return `${year}-${this.addZero(month)}-${this.addZero(day)}`;
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    filedClick() {
      if (this.disabled) return;
      this.showPopup = true;
    },
  },
};
</script>