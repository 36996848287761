<template>
  <div class="info-container">
    <!-- <NavBack :navBackMsg="navBackMsg"></NavBack> -->
    <!-- 覆盖最上方静止选择和修改数据 -->
    <div class="disabled-box" v-if="!saveShow"></div>
    <div class="info-cen">
      <div class="title">
        <svg class="closeImg" aria-hidden="true">
          <use :xlink:href="'#icon-jibenxinxi'" />
        </svg>
        {{ navBackMsg.title }}
      </div>
      <div class="info-main">
        <van-cell-group>
          <van-cell v-for="(item, index) in fromData" :key="index">
            <OcrIdentityView
              v-if="item.type === 'uploadImg01'"
              :value.sync="item.value"
              :disabled="!saveShow"
            >
            </OcrIdentityView>
            <van-field
              v-if="item.type === 'number'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              :disabled="item.identityCardOcr"
              type="digit"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'text'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              :disabled="item.identityCardOcr"
              right-icon="custom"
              @blur="getSexAndBirthday(item)"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'input2'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont"
                :class="
                  cardCode ? 'icon-zizhuruzhi-miwen' : 'icon-zizhuruzhi-mingwen'
                "
                class-prefix="icon"
                slot="right-icon"
                name="custom"
                @click="iconClick(item)"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'textArea'"
              v-model="item.value"
              :label="item.label"
              rows="1"
              type="textarea"
              :required="item.required"
              :autosize="item.autosize"
              :disabled="item.identityCardOcr"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <SelectView
              v-if="item.type === 'select'"
              :value.sync="item.value"
              :list="item.list"
              :label="item.label"
              :required="item.required"
              :disabled="item.identityCardOcr"
            ></SelectView>
            <DatetimeView
              v-if="item.type === 'date'"
              :value.sync="item.value"
              :type="item.type"
              :label="item.label"
              :required="item.required"
              :disabled="item.identityCardOcr"
            ></DatetimeView>
            <AreaView
              v-if="item.type === 'area'"
              :value.sync="item.value"
              :label="item.label"
              :required="item.required"
            ></AreaView>
            <UploadImgView
              v-if="item.type === 'uploadImg'"
              :label="item.label"
              :value.sync="item.value"
              :importType="item.fieldCode"
              :disabled="!saveShow"
            >
            </UploadImgView>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="footer">
      <van-button type="info" @click="save" v-btnClick v-if="saveShow"
        >保存</van-button
      >
    </div>
  </div>
</template>
<script>
import SelectView from "../../components/select-view/index"; //下拉选择
import DatetimeView from "../../components/Datetime-view/index"; //日期类型选择
import OcrIdentityView from "../../components/ocr/ocr-identity-view"; //身份证OCR
import AreaView from "../../components/Area-view/index";
import NavBack from "../../components/nav-back/index";
import UploadImgView from "../../components/upload-img-view/index"; //上传图片
import utils from "@/utils/index";
import { mapGetters } from "vuex";
import API from "../../api/http";
import { Toast } from "vant";
/**
  type 类型：
     input：普通input框,右边无icon
    input2: 右边icon有点击事件的input框
    select：下拉
    date：日期
    area：省市区
    textarea：多列展示
    identity：身份证
 */
export default {
  components: {
    SelectView,
    DatetimeView,
    AreaView,
    OcrIdentityView,
    NavBack,
    UploadImgView,
  },
  data() {
    return {
      navBackMsg: {
        title: "基本信息", // 导航文字内容
        backBtn: true,
      },

      fromData: [
        // {
        //   label: "户籍地址所在地",
        //   required: true,
        //   value: {
        //     companyProvince: "140000",
        //     companyCity: "140100",
        //     companyDistrict: "140105",
        //   },
        //   type: "area",
        // },
      ],
      cardCode: false,
      templateMenuId: "",
      cardData: {
        date_of_birth: true,
        ee_name: true,
        ethnicity: true,
        gender: true,
        id_authority: true,
        id_number: true,
        id_type: true,
        id_validity: true,
        nationality: true,
      },
      cardObj: {}, //ocr认证的值
      cardImg: {},
    };
  },
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode",
      temporaryCode: "temporaryCode",
      mcode: "mcode",
    }),
    saveShow() {
      if (this.mcode) {
        //人员状态未10、20,可以再次保存
        if (this.approvalStatus === 10 || this.approvalStatus === 20) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    ocrImg() {
      return this.fromData[0];
    },
  },
  watch: {
    ocrImg: {
      handler(val) {
        if (val.identityCardOcr) {
          this.ocrByIdCard(val.value);
        }
      },
      deep: true,
    },
  },
  created() {
    this.templateMenuId = this.$route.query.id;
    this.navBackMsg.title = this.$route.query.title;
    this.approvalStatus = Number(this.$route.query.astatus);
  },
  mounted() {
    this.getInit();
    // this.setFromData();
  },
  methods: {
    
    getInit() {
      API.getFieldByMenu({
        templateMenuId: this.templateMenuId,
        clientCode: this.clientCode,
        temporaryCode: this.temporaryCode,
        mcode: this.mcode,
      }).then((res) => {
        let result = res.data;
        this.fromData = this.setList(result[0]);
        // // 循环给出默认值
        this.fromData.map((item)=>{
          // 默认中国
          if(item.fieldCode === "nationality" && item.value === '中国') {
            item.value = 'CN'
          }
          // 默认居民身份证
          if(item.fieldCode === "id_type" && item.value === '居民身份证') {
            item.value = '1'
          }
          if(item.fieldCode === "ethnicity" && item.value === '汉族') {
            item.value = '1'
          }
        })
      });
    },
    setList(arr) {
      let menuRelList1 = arr.filter(
        (item) => item.propertyType === "uploadImg"
      );
      let menuRelList2 = arr.filter(
        (item) => item.propertyType !== "uploadImg"
      );
      let arr1 = menuRelList1.map((child) => {
        this.cardImg[child.fieldCode] = child.fieldValue;
        return {
          label: child.fieldDesc,
          value: child.fieldValue,
          fieldCode: child.fieldCode,
          type: child.propertyType,
          tipsDesc: child.tipsDesc,
          identityCardOcr: child.identityCardOcr === 1 ? true : false, //人事身份证ocr 0不使用1使用
          required: child.isNotNull === 1 ? true : false,
        };
      });

      let data = menuRelList2.map((child) => {
        return {
          label: child.fieldDesc, //字段名
          value: child.fieldValue, //字段值
          fieldCode: child.fieldCode, //字段code
          type: child.propertyType, //字段类型
          tipsDesc: child.tipsDesc, //字段提示
          identityCardOcr: false,
          // identityCardOcr:
          //   this.cardData[child.fieldCode] && child.identityCardOcr === 1,
          propertyLength:
            child.propertyLength &&
            child.propertyLength !== 0 &&
            child.propertyLength !== ""
              ? child.propertyLength
              : 200,
          list: child.downList.map((dom) => {
            return {
              value: dom.dataCode,
              text: dom.dataValue,
            };
          }),
          required: child.isNotNull === 1 ? true : false, //字段是否必填
        };
      });

      if (arr1.length > 0) {
        //人事身份证ocr 0不使用1使用
        let identityCardOcr = arr1[0].identityCardOcr;
        const imgs01 = arr1.filter(
          (item) =>
            item.fieldCode === "id_front_side_url" ||
            item.fieldCode === "id_back_side_url"
        );
        const imgs02 = arr1.filter(
          (item) =>
            item.fieldCode !== "id_front_side_url" &&
            item.fieldCode !== "id_back_side_url"
        );

        if (identityCardOcr) {
          data.unshift({
            label: "",
            type: "uploadImg01",
            identityCardOcr: identityCardOcr,
            value: [...imgs01],
          });
        } else {
          data.push({
            label: "",
            type: "uploadImg01",
            identityCardOcr: identityCardOcr,
            value: [...imgs01],
          });
        }
        data.push(...imgs02);
      }
      return data;
    },

    // 输入身份证号带出 出生日期和性别
    getSexAndBirthday(item) {
      if(item.fieldCode === "id_number" && item.value !== '') {
        this.fromData.map((v)=>{
          if(v.fieldCode === "date_of_birth") {
            v.value = this.getBirthday(item.value)
          }

          if(v.fieldCode === "gender") {
            v.value = this.getSex(item.value)
          }
        })
      } 

      if(item.fieldCode === "id_number" && item.value === '') {
        this.fromData.forEach((c)=>{
          if(["gender","date_of_birth"].includes(c.fieldCode)) {
            c.value = ''
          }
        })
      }
    },
    
    // 获取性别Fn
    getSex(idCard) {
      let sex = '';
      if (idCard.length === 18) {
        sex = idCard.substring(16, 17);
        sex = sex % 2 === 0 ? '0' : '1' // 性别代码 1代表男，0代表女，暂时不涉及其他类型性别
      } 

      return sex
    },

    // 获取出生日期Fn
    getBirthday(idCard) {
      let birthday = "";
      if (idCard != null && idCard !== "") {
       if (idCard.length === 18) {
          birthday = idCard.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }
      return birthday;
    },

    save() {
      let flag = true;
      let list = [...this.fromData[0].value, ...this.fromData.slice(1)];

      for (let item of list) {
        if (item.fieldCode === "age" && item.value.trim()) {
          if (item.value < 16) {
            let msg = item.label + "不能小于16岁";
            Toast({
              message: msg,
              className: "totast-cum",
            });
            flag = false;
            break;
          }
        } else {
          if (item.required && !item.value.trim()) {
            let msg = item.label + "不能为空";
            if (item.tipsDesc) {
              msg = item.tipsDesc + item.label;
            }

            Toast({
              message: msg,
              className: "totast-cum",
            });
            flag = false;
            break;
          }
        }
      }

      if (flag) {
        //成功

        if (!this.setType()) {
          return;
        }
        let menuFieldRelList = [];
        let func = (arr) => {
          arr.forEach((element) => {
            if (Array.isArray(element.value)) {
              func(element.value);
            } else {
              menuFieldRelList.push({
                fieldCode: element.fieldCode,
                fieldValue: element.value,
              });
            }
          });
        };
        func(this.fromData);
        API.addBasicFieldVale({
          templateMenuId: this.templateMenuId,
          temporaryCode: this.temporaryCode,
          clientCode: this.clientCode,
          menuFieldRelList: [menuFieldRelList],
          mcode: this.mcode,
        }).then((res) => {
          Toast({
            message: res.message,
            className: "toast-yellow",
          });
          this.saveBack();
        });
      }
    },
    saveBack() {
      setTimeout(() => {
        this.$router.back(-1);
        // this.$router.push({ path: "/user", query: { ...this.$route.query } });
      }, 1000);
    },
    setType() {
      //判断证件类型为 身份信息是  证件号码是否输入正确
      let map = {};
      for (let item of this.fromData) {
        map[item.fieldCode] = item.value;
      }

      if (map["id_type"] === "1" && !utils.setIdCardText(map["id_number"])) {
        Toast({
          message: "证件号码填写错误",
          className: "totast-cum",
        });
        return false;
      }
      return true;
    },
    setFromData() {
      //将省份证号码转为 密文显示
      this.fromData.map((item) => {
        if (item.type === "input2") {
          this.cardCodeValue = JSON.parse(JSON.stringify(item.value));
          item.value = utils.setIdCard(item.value);
        }
      });
    },

    iconClick(item) {
      //身份证  铭文/密文切换
      this.cardCode = !this.cardCode;
      item.value = this.cardCode
        ? this.cardCodeValue
        : utils.setIdCard(item.value);
    },
    ocrByIdCard(arr) {
      let arr01 = arr.filter((item) => item.required);
      let arr02 = arr.filter((item) => item.value);
      let arr03 = arr.filter((item) => item.value && item.required);
      if (arr01.length === 2) {
        //头像面和国徽面都必填
        if (arr03.length === arr01.length) {
          this.pubOcrCard(arr02);
        }
      } else {
        //两者都没有必填
        this.pubOcrCard(arr02);
      }
    },
    pubOcrCard(arr) {
      if (this.getCardImg(arr)) {
        let list = arr.map((element) => {
          return {
            fieldCode: element.fieldCode,
            fieldValue: utils.getImgUrl() + element.value,
            // fieldValue:
            //   "https://dev.tranderpay.com/tranderpayfiles" + element.value,
          };
        });
        API.findOcrByIdCard([list]).then((res) => {
          if (JSON.stringify(res.data) === "{}") {
            // 没有识别出信息
            Toast({
              message: "身份信息识别失败",
              className: "totast-cum",
            });
            return;
          }
          this.cardObj = res.data;
          this.setCardList();
        });
      }
    },
    getCardImg(arr) {
      //图片是否有改变
      let flag = false;
      for (let key of arr) {
        if (key.value !== this.cardImg[key.fieldCode]) {
          flag = true;
          break;
        }
      }
      return flag;
    },

    setCardList() {
      this.fromData.forEach((item) => {
        if (this.cardObj[item.fieldCode]) {
          item.value = this.cardObj[item.fieldCode];
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
</style>